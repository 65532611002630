import React from "react";
import { motion } from "framer-motion";
import profileImg from "../assets/profile-pic.png";
import linkedInImg from "../assets/linkedin.png";
import gitHubImg from "../assets/github.png";

const Profile = () => {
  const linkedInUrl = "https://www.linkedin.com/in/chen-li-dev/";
  const gitURL = "https://github.com/chenli12138/";

  const handleDownload = () => {
    const url = "./Chen_Li_Resume.pdf";
    const filename = "Chen_Li_Resume.pdf";
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = filename;
        a.click();
      });
    });
  };

  const scrollToContact = () => {
    const contactSection = document.getElementById("contact");
    contactSection?.scrollIntoView({ behavior: "smooth" });
    console.log("working");
  };

  return (
    <>
      <motion.div
        className="section__pic-container"
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <img src={profileImg} alt="Chen Li profile" />
      </motion.div>

      <motion.div
        className="section__text"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.p
          className="section__text__p1"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2, duration: 0.6 }}
        >
          Hello, I'm
        </motion.p>
        <motion.h1
          className="title"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.4, duration: 0.6 }}
        >
          Chen Li
        </motion.h1>
        <motion.p
          className="section__text__p2"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.6, duration: 0.6 }}
        >
          Frontend Developer
        </motion.p>
        <motion.div
          className="btn-container"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 0.6 }}
        >
          <button className="btn btn-color-2" onClick={handleDownload}>
            Download CV
          </button>
          <button className="btn btn-color-1" onClick={scrollToContact}>
            Contact Info
          </button>
        </motion.div>
        <motion.div
          id="socials-container"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 0.6 }}
        >
          <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
            <img
              src={linkedInImg}
              alt="My LinkedIn profile"
              className="icon cursor-pointer"
            />
          </a>
          <a href={gitURL} target="_blank" rel="noopener noreferrer">
            <img
              src={gitHubImg}
              alt="My Github profile"
              className="icon cursor-pointer"
            />
          </a>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Profile;
