import React from "react";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import aboutImg from "../assets/about-pic-2.png";
import expImg from "../assets/experience.png";
import arrowIcon from "../assets/arrow.png";
import eduImg from "../assets/education.png";

const AboutMe = () => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: true, amount: 0.2 });

  const arrowHandler = () => {
    window.location.href = "#experience";
  };

  return (
    <motion.div ref={sectionRef}>
      {isInView && (
        <>
          {/* Header Text */}
          <motion.p
            className="section__text__p1"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Get To Know More
          </motion.p>
          <motion.h1
            className="title"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            About Me
          </motion.h1>

          {/* Section Container */}
          <motion.div
            className="section-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 1 }}
          >
            {/* About Image */}
            <motion.div
              className="section__pic-container"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.8 }}
            >
              <img
                src={aboutImg}
                alt="About me - Chen Li"
                className="about-pic"
              />
            </motion.div>

            {/* About Details */}
            <motion.div
              className="about-details-container"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.8, duration: 1 }}
            >
              <div className="about-containers">
                {/* Experience Section */}
                <motion.div
                  className="details-container"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1, duration: 0.8 }}
                >
                  <img src={expImg} alt="Experience icon" className="icon" />
                  <h3>Experience</h3>
                  <p>
                    2+ years <br />
                    Full Stack Development
                  </p>
                </motion.div>

                {/* Education Section */}
                <motion.div
                  className="details-container"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1.2, duration: 0.8 }}
                >
                  <img src={eduImg} alt="Education icon" className="icon" />
                  <h3>Education</h3>
                  <p>
                    Bachelor of Information Technology
                    <br />
                    UTS
                  </p>
                </motion.div>
              </div>

              {/* Description Text */}
              <motion.div
                className="text-container"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.4, duration: 0.8 }}
              >
                <p>
                  Tech-savvy junior web developer with one year full stack
                  developing experience. Streamline building intuitive and
                  responsive user interfaces with a great master of HTML, CSS,
                  JavaScript, and React, and server-side PHP. Hands-on
                  experience taking charge of front and back-end web development
                  across multiple APIs, third-party integrations and databases.
                  Seeking to leverage the characters of a strong communicator,
                  quick learner, and detail-oriented problem solver to perform
                  better service for next opportunity.
                </p>
              </motion.div>
            </motion.div>
          </motion.div>

          {/* Arrow Icon */}
          <motion.img
            src={arrowIcon}
            alt="Arrow icon"
            className="icon arrow"
            initial={{ y: 0 }}
            animate={{ y: [0, -10, 0] }}
            transition={{
              repeat: Infinity,
              repeatType: "loop",
              duration: 1,
            }}
            onClick={arrowHandler}
          />
        </>
      )}
    </motion.div>
  );
};

export default AboutMe;
