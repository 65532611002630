"use client";

import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import linkedInImg from "../assets/linkedin.png";
import emailImg from "../assets/email.png";

const Contact = () => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: true, amount: 0.2 });

  return (
    <motion.div ref={sectionRef} className="contact-section">
      {isInView && (
        <>
          {/* Section Header */}
          <motion.p
            className="section__text__p1"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Get in Touch
          </motion.p>
          <motion.h1
            className="title"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Contact Me
          </motion.h1>

          {/* Contact Information */}
          <motion.div
            className="contact-info-upper-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 1 }}
          >
            {/* Email */}
            <motion.div
              className="contact-info-container"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.8 }}
            >
              <img
                src={emailImg}
                alt="Email icon"
                className="icon contact-icon email-icon"
              />
              <p>
                <a href="mailto:chenli.dev@outlook.com">
                  chenli.dev@outlook.com
                </a>
              </p>
            </motion.div>

            {/* LinkedIn */}
            <motion.div
              className="contact-info-container"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.8 }}
            >
              <img
                src={linkedInImg}
                alt="LinkedIn icon"
                className="icon contact-icon"
              />
              <p>
                <a
                  href="https://www.linkedin.com/in/chen-li-dev/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </p>
            </motion.div>
          </motion.div>
        </>
      )}
    </motion.div>
  );
};

export default Contact;
