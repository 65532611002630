import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import arrowIcon from "../assets/arrow.png";
import ExpTag from "./expTag";

const Experience = () => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: true, amount: 0.2 });

  return (
    <motion.div ref={sectionRef}>
      {isInView && (
        <>
          {/* Section Header */}
          <motion.p
            className="section__text__p1"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Explore My
          </motion.p>
          <motion.h1
            className="title exp-gap"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Experience
          </motion.h1>

          {/* Experience Details */}
          <motion.div
            className="experience-details-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 1 }}
          >
            <motion.div
              className="about-containers"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.8 }}
            >
              {/* Frontend Development */}
              <motion.div
                className="details-container"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8, duration: 0.8 }}
              >
                <h2 className="experience-sub-title">Frontend Development</h2>
                <motion.div
                  className="article-container"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1, duration: 1 }}
                >
                  <ExpTag code="HTML" lvl="Experienced" />
                  <ExpTag code="CSS" lvl="Experienced" />
                  <ExpTag code="React" lvl="Advanced" />
                  <ExpTag code="TypeScript" lvl="Advanced" />
                  <ExpTag code="Material UI" lvl="Experienced" />
                  <ExpTag code="Tailwind" lvl="Skillful" />
                </motion.div>
              </motion.div>

              {/* Backend Development */}
              <motion.div
                className="details-container"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.2, duration: 0.8 }}
              >
                <h2 className="experience-sub-title">Backend Development</h2>
                <motion.div
                  className="article-container"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1.4, duration: 1 }}
                >
                  <ExpTag code="PHP" lvl="Experienced" />
                  <ExpTag code="Node" lvl="Experienced" />
                  <ExpTag code="MySQL" lvl="Experienced" />
                  <ExpTag code="REST API" lvl="Experienced" />
                  <ExpTag code="Git" lvl="Advanced" />
                  <ExpTag code="Docker" lvl="Experienced" />
                  <ExpTag code="Linux" lvl="Experienced" />
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div>

          {/* Arrow Icon */}
          <motion.img
            src={arrowIcon}
            alt="Arrow icon"
            className="icon arrow mt-8"
            initial={{ y: 0 }}
            animate={{ y: [0, -10, 0] }}
            transition={{
              repeat: Infinity,
              repeatType: "loop",
              duration: 1,
            }}
            onClick={() => (window.location.href = "#projects")}
          />
        </>
      )}
    </motion.div>
  );
};

export default Experience;
