import React from "react";
import { motion } from "framer-motion";

function NaviBig() {
  // Variants for the nav links to animate them in sequence
  const navItemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.2, // Staggered entrance for each item
        duration: 0.5,
      },
    }),
  };

  return (
    <motion.nav
      id="desktop-nav"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      {/* Logo Animation */}
      <motion.div
        className="logo"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        Chen Li
      </motion.div>

      {/* Nav Links Animation */}
      <motion.ul className="nav-links" initial="hidden" animate="visible">
        {["About", "Experience", "Projects", "Contact"].map((item, index) => (
          <motion.li key={item} custom={index} variants={navItemVariants}>
            <a href={`#${item.toLowerCase()}`}>{item}</a>
          </motion.li>
        ))}
      </motion.ul>
    </motion.nav>
  );
}

export default NaviBig;
