import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import project1 from "../assets/project-1.png";
import project2 from "../assets/project-2.jpg";
import project3 from "../assets/project-3.png";
import arrowIcon from "../assets/arrow.png";

const Projects = () => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: true, amount: 0.2 });

  const linkHandler = (url) => {
    window.location.href = url;
  };

  return (
    <motion.div ref={sectionRef}>
      {isInView && (
        <>
          {/* Section Header */}
          <motion.p
            className="section__text__p1"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Browse My Recent
          </motion.p>
          <motion.h1
            className="title"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Projects
          </motion.h1>

          {/* Project Details */}
          <motion.div
            className="experience-details-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 1 }}
          >
            <motion.div
              className="about-containers"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.8 }}
            >
              {/* Project 1 */}
              <motion.div
                className="details-container color-container"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8, duration: 1 }}
              >
                <div className="article-container">
                  <img src={project1} alt="Project 1" className="project-img" />
                </div>
                <h2 className="experience-sub-title project-title">
                  Trailflix
                </h2>
                <div className="btn-container">
                  <button
                    className="btn btn-color-2 project-btn"
                    onClick={() =>
                      linkHandler("https://github.com/chenli12138/trailflix")
                    }
                  >
                    Github
                  </button>
                  <button
                    className="btn btn-color-2 project-btn"
                    onClick={() =>
                      linkHandler("https://trailflix-pi.vercel.app/")
                    }
                  >
                    Live Demo
                  </button>
                </div>
              </motion.div>

              {/* Project 2 */}
              <motion.div
                className="details-container color-container"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <div className="article-container">
                  <img src={project2} alt="Project 2" className="project-img" />
                </div>
                <h2 className="experience-sub-title project-title">
                  Ella Makeup
                </h2>
                <div className="btn-container">
                  <button
                    className="btn btn-color-2 project-btn"
                    onClick={() =>
                      linkHandler("https://github.com/chenli12138/ella_makeup")
                    }
                  >
                    Github
                  </button>
                  <button
                    className="btn btn-color-2 project-btn"
                    onClick={() => linkHandler("https://ellamakeup.com.au/")}
                  >
                    Live Demo
                  </button>
                </div>
              </motion.div>

              {/* Project 3 */}
              <motion.div
                className="details-container color-container"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.2, duration: 1 }}
              >
                <div className="article-container">
                  <img src={project3} alt="Project 3" className="project-img" />
                </div>
                <h2 className="experience-sub-title project-title">
                  Project Three
                </h2>
                <div className="btn-container">
                  <button
                    className="btn btn-color-2 project-btn"
                    onClick={() => linkHandler("https://github.com/")}
                  >
                    Github
                  </button>
                  <button
                    className="btn btn-color-2 project-btn"
                    onClick={() => linkHandler("https://github.com/")}
                  >
                    Live Demo
                  </button>
                </div>
              </motion.div>
            </motion.div>
          </motion.div>

          {/* Arrow Icon */}
          <motion.img
            src={arrowIcon}
            alt="Arrow icon"
            className="icon arrow mt-8"
            initial={{ y: 0 }}
            animate={{ y: [0, -10, 0] }}
            transition={{
              repeat: Infinity,
              repeatType: "loop",
              duration: 1,
            }}
            onClick={() => (window.location.href = "#contact")}
          />
        </>
      )}
    </motion.div>
  );
};

export default Projects;
